.compliance-upload-file-delete-btn {
    cursor: pointer;
}

.compliance-upload-file-delete-btn:hover {
    color: grey;
}

.compliance-upload-file-delete-btn:active {
    transform: translateY(1px);
}