.create-form-input {
  width: 180px;
  margin-right: 20px;
}

.display-center {
  display: flex;
  justify-content: center;
}

.quesstionnaire-filter-each-filter {
  width: 23%;
  min-width: 200px;
  margin-right: 10px;
  margin-top: 10px;
}

.test-plan-add-spec-button {
  height: 20px;
  padding-top: 15px;
}

.button-with-margin {
  margin-right: 10px;
}

.create-project-input {
  width: 200px;
}

.edit-row-input {
  max-width: 120px;
  width: auto;
}
