body {
    background-color: var(--background);
    font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
    /* background-color: var(--normal-white); */
  }
  
  /* Need to override the padding property for Polaris Icon */
  .awsui-icon.awsui-icon-size-normal {
    padding: 0px 0px !important;
  }
  
  .awsui-toggle {
    margin-bottom: 0 !important;
  }
  
  /* overwrite bootstrap tooltip inner*/
  .tooltip-inner {
    font-size: 12px !important;
    padding: 5px;
  }
  
  /* remove the wired outline in firefox when focusing on link/button/input */
  :focus {
    outline: none !important;
  }
  
  /* bootstrap overwrites the default table css, which breaks the style of polaris */
  table {
    border-collapse: separate;
  }