.compliance-upload-dropzone-container {
    display: flex;
    margin-bottom: 10px;
  }
  
  .compliance-upload-dropzone {
    /* flex: 1; */
    /* width: 100%; */
    width: 800px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }