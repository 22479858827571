.region-overview-box-fix-height{
  height: 450px;
  display:table-cell;
  vertical-align: middle;
  text-align: center;
}
.alert-container{
    min-height: 400px;
    display: flex;
    align-items: center;
    align-content: center;
}
.config-button{
color: blue; 
font-weight: 500;
}
.config-button:hover{
 cursor: pointer;
}