/* 
    Color config specificly for current project 

    if you find some color will be general across all initiatives,
    then we can move to LENS-React-UI: https://code.amazon.com/packages/LENS-React-UI/blobs/mainline/--/src/index.css
*/
:root {
    /* test status */
    --test-status-failed: #df2a02;
    --test-status-passed: #4bb102;
    --test-status-warning: #ecb201dc;
    --test-status-no-limit: #222e3e;
    --test-status-untested: #acabab;
  
    /* landing */
    --landing-in-process: #017bff;
    --landing-archived: #4bb102;
    --landing-not-start: #6c757c;
  }