.visualization-box-fix-height {
  height: 1100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.visualization-alert-container {
  min-height: 630px;
  display: flex;
  align-items: center;
  align-content: center;
}

.MuiChip-label {
  margin-right: 16px;
  font-size: 15px;
  padding: 8px;
}
