.wise-main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.disp-flex {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.text-input {
  height: 30px;
  color: var(--wise-black);
  text-align: center;
  padding: 0.2rem 1rem;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 1.2rem;
  line-height: 1.42rem;
  border-radius: 1px;
  margin: 2px;
  display: inline-block;
}

.table-heading {
  color: var(--wise-black);
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 1.5rem;
}

.table-heading-red {
  color: var(--wise-red);
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 1.5rem;
}

.test-plan-meta-heading {
  color: var(--wise-black);
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 0.8rem;
}

.test-plan-btn-submit {
  width: 190px;
  align-content: center;
}

.test-plan-table {
  margin-top: 18px;
  border: 1px solid var(--wise-boxShadow);
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
}
.test-plan-table-container {
  width: 99%;
}

.row-id {
  width: 10%;
}
.row-name {
  width: 45%;
}
.row-config {
  width: 10%;
}
.row-section {
  width: 25%;
}
.row-action {
  width: 10%;
}

.test-plan-form-error {
  color: red;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
}

.test-plan-input-error {
  height: 55px;
  border: 2px solid red;
  text-align: center;
  margin: 2px;
}

.test-plan-input {
  height: 55px;
  border: 1px solid var(--input-border);
}

.input-id {
  min-width: 60%;
  max-width: 60%;
}

.input-section {
  min-width: 95%;
  max-width: 95%;
}
.input-config {
  min-width: 95%;
  max-width: 95%;
}
.input-name {
  min-width: 100%;
  max-width: 100%;
}

.input-assignee {
  height: 29px;
  width: 190px;
  border: 1px solid var(--wise-black);
  right: 260px;
  position: absolute;
}
.input-assignee-error {
  height: 30px;
  width: 190px;
  border: 2px solid red;
  right: 260px;
  position: absolute;
}

.search-dropdown {
  height: 5.1em;
  overflow: hidden;
}

.section-add-btn {
  right: 65px;
  width: 190px;
  position: unset !important;
}

.section-input {
  height: 30px;
  width: 190px;
  border: 1px solid var(--wise-black);
  right: 260px;
}

.section-heading {
  border: 0px;
  background-color: white;
  text-align: center;
  color: darkslategrey;
  font-weight: 400;
  font-size: 16px;
}
.icon-button {
  width: 30px;
  height: 30px;
  background-color: white;
  border: none !important;
  padding: 2px;
}

.section-tabs {
  margin: 0px;
  border-color: #fff #fff rgb(32, 12, 212) !important;
}

.nav-tabs .nav-link {
  background-color: white;
  margin-bottom: 0px;
}

.nav-tabs .nav-link.active {
  border-width: 3px;
  border-color: rgb(32, 12, 212) rgb(32, 12, 212) #fff !important;
}

.normal:hover {
  background-color: var(--wise-grey);
  color: var(--wise-black);
}
.test-case-table {
  height: 20px;
}

.test-plan-meta-info-block {
  width: 100%;
  height: 70px;
  padding: 10px !important;
  background-color: #dee2e64a;
}

.add-sheet-block {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #dee2e64a;
  justify-content: flex-end !important;
  display: flex;
  gap: 6px;
}

.icon-hover:hover {
  cursor: pointer;
}

.icon-hover:active {
  transform: translateY(1px);
}

.submitted-message {
  text-align: center;
  color: green;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 1.5rem;
}

.submit-failed-message {
  text-align: center;
  color: red;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 1.5rem;
}

.section-ul {
  position: absolute;
  margin-top: 40px;
  height: 30px;
  width: 190px;
  right: 300px;
  color: var(--wise-black);
}

.section-ul:hover {
  cursor: pointer;
}

.list-border {
  width: 192px;
  border: 1px solid var(--wise-black);
}

.testplan-fields {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.section-container {
  padding: 0px !important;
}
.testplan-section {
  padding: 10px;
}
