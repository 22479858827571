.visualization-alert-container{
    min-height: 658px;
    display: flex;
    align-items: center;
    align-content: center;
}
.legend-box-red {
    margin-top: 7px;
    min-height: 10px;
    max-height: 10px;
    min-width: 10px;
    max-width: 10px;
    background-color: red;
}
.legend-box-green {
    margin-top: 7px;
    min-height: 10px;
    max-height: 10px;
    min-width: 10px;
    max-width: 10px;
    background-color: green;
}
.legend-box-blue {
    margin-top: 7px;
    min-height: 10px;
    max-height: 10px;
    min-width: 10px;
    max-width: 10px;
    background-color: #0077CC;
}