.engineering-tooltips {
    position: fixed;
    /* float: left; */
    display: none;
    z-index: 1;
    background-color: white;
    border: 1px solid var(--wise-black);
  }
  
  #engineering-landing-progress:hover > .engineering-tooltips {
    display: block;
  }