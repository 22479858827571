.cell-style {
    width: '100%';
    text-align: center;
}

.p-style {
    width: '100%';
    text-align: center;
}

.p-style:hover {
    cursor: pointer;
}