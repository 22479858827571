.wise-bootstrap-table {
  background-color: var(--normal-white);
  margin-bottom: 5px !important;
  overflow-x: scroll !important;
}

.wise-bootstrap-table-header {
  font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  background-color: #fafafa;
  word-wrap: break-word;
  overflow-x: auto !important;
}

.wise-bootstrap-table-header th {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: none !important;
}
